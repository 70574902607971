class DefaultError extends Error {
  static message(err) {
    let msg = "";

    if (typeof err === "string") msg = err;
    else if (typeof err === "object" && err.message) msg = err.message;

    return msg.trim();
  }
}

class DatabaseError extends DefaultError {
  static isSequelizeError(err) {
    return err.name === "SequelizeDatabaseError" || err.parent;
  }

  static sequelize(err) {
    let errorMessages = [];

    if (err.parent) {
      err.parent.message && errorMessages.push(err.parent.message);
      err.parent.errors &&
        (errorMessages = errorMessages.concat(err.parent.errors));
    }

    return this.message(
      errorMessages.length ? errorMessages.join("\n\n") : err
    );
  }
}

class ApiError extends DefaultError {
  static status = 0;

  constructor(message, status) {
    super(message);
    this.status = status;
  }

  static isApiError(err) {
    return Boolean(err.response);
  }

  static default(err) {
    let msg = "";

    if (err.response && err.response.data) {
      if (typeof err.response.data === "string") msg = err.response.data;
      else if (err.response.data.exceptionMessage)
        msg = err.response.data.exceptionMessage;
      else if (err.response.data.message) msg = err.response.data.message;
    }

    return this.message(msg || err);
  }

  static acumatica(err) {
    let msg = "";

    if (
      err.response &&
      err.response.data &&
      typeof err.response.data === "string"
    ) {
      const localError = err.response.data;

      const htmlpre = localError.substr(
        localError.indexOf("<pre>") + 5,
        localError.indexOf("</pre>")
      );

      htmlpre.length && (msg = htmlpre.substring(0, htmlpre.indexOf("<br>")));
    }

    return this.default(msg || err);
  }
}

class InternalServerError extends ApiError {
  static status = 500;

  constructor(message) {
    super(message, InternalServerError.status);
  }
}

class BadRequestError extends ApiError {
  static status = 400;

  constructor(message) {
    super(message, BadRequestError.status);
  }
}

class UnauthorizedError extends ApiError {
  static status = 401;

  constructor(message) {
    super(message, UnauthorizedError.status);
  }
}

class ForbiddenError extends ApiError {
  static status = 403;

  constructor(message) {
    super(message, ForbiddenError.status);
  }
}

class NotFoundError extends ApiError {
  static status = 404;

  constructor(message) {
    super(message, NotFoundError.status);
  }
}

class BadGatewayError extends ApiError {
  static status = 502;

  constructor(message) {
    super(message, BadGatewayError.status);
  }
}

class GatewayTimeoutError extends ApiError {
  static status = 504;

  constructor(message) {
    super(message, GatewayTimeoutError.status);
  }
}

module.exports = {
  DefaultError,
  DatabaseError,
  ApiError,
  InternalServerError,
  BadRequestError,
  UnauthorizedError,
  ForbiddenError,
  NotFoundError,
  BadGatewayError,
  GatewayTimeoutError,
};
