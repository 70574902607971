class Bcrypt {
  constructor(bcrypt) {
    this.bcrypt = bcrypt;
  }

  async hashPassword(passwordToBeHashed) {
    const salt = await this.bcrypt.genSalt(10);
    const hashedValue = await this.bcrypt.hash(passwordToBeHashed, salt);
    return hashedValue;
  }

  isMatchingPassword(hashedPassword, unhashedPassword) {
    return this.bcrypt.compare(unhashedPassword, hashedPassword);
  }
}

module.exports = { Bcrypt };
