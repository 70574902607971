const utf8 = require("utf8");

class MyCryptr {
  constructor(cryptrSecret, Cryptr) {
    this.cryptr = new Cryptr(cryptrSecret);
  }

  encrypt(plainText) {
    return plainText ? this.cryptr.encrypt(plainText) : plainText;
  }

  encodeAndEncrypt(plainText) {
    return this.encrypt(utf8.encode(plainText));
  }

  encryptInJSON(decryptedJSON, keysString, delimiter) {
    let encryptedJSON = {
      ...decryptedJSON,
    };

    const propsToEncryptArray = keysString.split(delimiter);
    const propsToEncryptSet = new Set(propsToEncryptArray);

    for (const [key, value] of Object.entries(decryptedJSON)) {
      encryptedJSON = {
        ...encryptedJSON,
        [key]: propsToEncryptSet.has(key) ? this.encrypt(value) : value,
      };
    }

    return encryptedJSON;
  }

  decrypt(encryptedText) {
    return encryptedText ? this.cryptr.decrypt(encryptedText) : encryptedText;
  }

  decryptAndDecode(encryptedString) {
    return utf8.decode(this.decrypt(encryptedString));
  }

  decryptInJSON(encryptedJSON, keysString, delimiter) {
    let decryptedJSON = {
      ...encryptedJSON,
    };

    const propsToDecryptArray = keysString.split(delimiter);
    const propsToDecryptSet = new Set(propsToDecryptArray);

    for (const [key, value] of Object.entries(decryptedJSON)) {
      decryptedJSON = {
        ...decryptedJSON,
        [key]: propsToDecryptSet.has(key) ? this.decrypt(value) : value,
      };
    }

    return decryptedJSON;
  }
}

module.exports = { Cryptr: MyCryptr };
