class JwtToken {
  constructor(secret, jwt) {
    this.secret = secret;
    this.jwt = jwt;
  }

  create({ iat, exp, ...payload }, options = {}) {
    return this.jwt.sign(payload, this.secret, options);
  }

  decode(token, options = {}) {
    return this.jwt.verify(token, this.secret, options);
  }
}

module.exports = { JwtToken };
