class Mongoose {
  constructor(uri, mongoose) {
    this.uri = uri;
    this.mongoose = mongoose;
  }

  connectToMongoDb(options) {
    return this.mongoose.connect(this.uri, {
      useNewUrlParser: true,
      useUnifiedTopology: true,
      ...(options || {}),
    });
  }

  defineSchema(json) {
    return this.mongoose.Schema(json);
  }

  createModel(name, schema) {
    return this.mongoose.model(
      name,
      schema instanceof this.mongoose.Schema
        ? schema
        : this.defineSchema(schema)
    );
  }
}

class Sequelize {
  constructor(sequelize, username, password, database, host, options = {}) {
    this.sequelize = new sequelize.Sequelize({
      dialect: "mssql",
      pool: {
        max: 5,
        min: 0,
        acquire: 30000,
        idle: 10000,
      },
      ...options,
      username,
      password,
      database,
      host,
    });
  }

  authenticate() {
    return this.sequelize.authenticate();
  }

  async executeSQL(sqlString, options, authenticateFirst = false) {
    authenticateFirst && (await this.authenticate());
    return this.sequelize.query(sqlString, options);
  }

  executeStoredProcedure(
    sqlString,
    valuesJson,
    queryType,
    authenticateFirst = false
  ) {
    return this.executeSQL(
      sqlString,
      {
        replacements: valuesJson,
        type: queryType,
      },
      authenticateFirst
    );
  }
}

module.exports = { Mongoose, Sequelize };
