function parseCookie(cookieString) {
  return cookieString.split("; ").reduce((parsedCookie, cookieString) => {
    const parsedString = cookieString.split("=");
    parsedCookie[parsedString[0]] = parsedString[1];
    return parsedCookie;
  }, {});
}

function createTokenCookieAzureFunction(
  name,
  token,
  isProductionEnv,
  options = {}
) {
  const envConfig = isProductionEnv
    ? {
        secure: true,
        httpOnly: true,
        sameSite: "none",
      }
    : {};
  return {
    ...envConfig,
    ...options,
    name,
    value: token,
  };
}

function cookieExpireTime(duration) {
  const match = duration.match(/^(\d+)([smhd])$/);
  if (!match) {
    console.error(
      "Invalid duration format. Please use a format like '1s', '60m', or '5d'."
    );
    return null;
  }

  const amount = parseInt(match[1]);
  const unit = match[2];

  // Map units to milliseconds
  const unitToMs = {};
  unitToMs.ms = 1;
  unitToMs.s = 1000 * unitToMs.ms;
  unitToMs.m = 60 * unitToMs.s;
  unitToMs.h = 60 * unitToMs.m;
  unitToMs.d = 24 * unitToMs.h;
  unitToMs.y = 365 * unitToMs.d;

  if (!unitToMs[unit]) {
    console.error("Invalid time unit. Please use 's', 'm', 'h', or 'd'.");
    return null;
  }

  // Calculate and return the expiration time
  return new Date(Date.now() + amount * unitToMs[unit]);
}

module.exports = {
  parseCookie,
  createTokenCookieAzureFunction,
  cookieExpireTime,
};
