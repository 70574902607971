const cookie = require("./cookie");
const database = require("./database");
const middleware = require("./middleware");
const password = require("./password");
const security = require("./security");
const token = require("./token");
const error = require("./error");

module.exports = {
  ...cookie,
  ...database,
  ...middleware,
  ...password,
  ...security,
  ...token,
  ...error,
};
